import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PortfolioRefreshService {
  private _portfolioNotification$ = new BehaviorSubject<any>(null);

  constructor() {}

  sendMessage(message: any): void {
    this._portfolioNotification$?.next(message);
  }

  receiveMessage(portfolioId: number | null): Observable<any> {
    if (!portfolioId) {
      return of(null);
    }
    return this._portfolioNotification$.pipe(
      filter((data: any) => {
        if (!data) {
          return true;
        }
        return data.portfolioId === portfolioId;
      })
    );
  }

  // heartbeat$: Observable<void> = this.receivePortfolioRefreshBeat().pipe(
  //   combineLatestWith(this.ctx.portfolioId$),
  //   filter((args) => {
  //     return args[0].portfolioId?.toString() === args[1]?.toString();
  //   }),
  //   map((_) => void 0),
  //   startWith(void 0),
  //   shareReplay({
  //     refCount: true,
  //     bufferSize: 1
  //   })
  // );

  // private receivePortfolioRefreshBeat() {
  //   // return this.socket.fromEvent<PortfolioUpdateNotification>(eventName);
  //   return this.socket.socket$;
  //   // return this.socket.fromEvent(event).pipe(map((data: any) => data.msg));
  // }

  // ngOnDestroy() {
  //   this.disconnect();
  // }

  // async disconnect() {
  //   return new Promise((resolve) => {
  //     this.socket.socket$?.complete();
  //     // this.socket.removeListener(eventName, () => {
  //     //   resolve('done');
  //     // });
  //   });
  // }
}

interface PortfolioUpdateNotification {
  portfolioId: number;
}
